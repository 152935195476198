/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useStore } from "src/lib/store";

function Toast() {
  // States
  const { toast, setToast } = useStore((state) => state);

  // Functions
  const handleClickClose = () => {
    setToast({ isToast: false, message: null, status: "" });
  };

  useEffect(() => {
    if (toast.isToast) {
      setTimeout(() => {
        setToast({ isToast: false, message: toast.message, status: "" });
        setTimeout(() => {
          setToast({ isToast: false, message: null, status: "" });
        }, 200);
      }, 3000);
    }
  }, [toast?.isToast]);

  return (
    <div className={`toast-wrapper ${toast.isToast ? "show" : "hide"}`}>
      <div
        className={`toast-state ${toast.isToast ? "show" : "hide"} ${
          toast.status
        }`}
      >
        <div
          className="close-toast"
          onClick={handleClickClose}
          role="button"
          tabIndex={0}
        >
          <i className="ail ai-times pointer" />{" "}
        </div>
        {typeof toast?.message === "function" ? (
          <div
            dangerouslySetInnerHTML={{
              __html: toast.message(),
            }}
          ></div>
        ) : (
          <p>{toast.message}</p>
        )}
        {/* <span>{toast?.message} </span> */}
      </div>
    </div>
  );
}

export default Toast;
